// author:马云阳；description:众惠-公募-填写转换信息2
<template>
    <section class="PublicFundProcess">
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont">
            <a @click="toGos" class="title_">
             <span class="iconfont">&#xe665;</span> 公募基金
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
           <div class="card_">
              <img src="@/static/img/my/PasswordAlterSuccess.png" class="true_" alt="">
              <div class="true_ti">
                <div class="tis_">线下转账申请提交成功</div>
                <div class="timers_">{{newdates}}</div>
              </div>
              <div class="timerisold_">请在<span class="timers__">{{Ns}}</span>前完成转账</div>
              <div class="side_box_">
                <div class="rduis_">成功<br/>转账</div>
                <i></i>
                <div class="rduis_">自动<br/>匹配</div>
                <i></i>
                <div class="rduis_">匹配<br/>结果</div>
              </div>
              <div class="side_txt_">
                <div class="flex_side">
                  <div class="left_side ac_">开户银行：</div>
                  <span class="ac_">中国民生银行股份有限公司贵阳分行营业部</span>
                </div>
                <div class="flex_side">
                  <div class="left_side ac_">60分钟内完成匹配</div>
                  <span class="iconfont ac_">&#xe65c;</span>
                </div>
                <div class="flex_side">
                  <div class="left_side ac_">转账银行卡与订单银行卡一致</div>
                  <span class="iconfont ac_">&#xe65c;</span>
                </div>
                <div class="flex_side">
                  <div class="left_side ac_">转账金额与订单金额一致</div>
                  <span class="iconfont ac_">&#xe65c;</span>
                </div>
              </div>
              <a  @click="toGos" class="topage_">
                返回公募基金
              </a>
          </div>
          
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "BuyingFundOfflinefour",
  components: { ProgressBar },
  data() {
    return {
      side: [
        {
          title: "填写买入信息",
          type: 1
        },
        {
          title: "核对线下转账信息",
          type: 1
        },
        {
          title: "交易结果",
          type: 2
        }
      ],
      newdates: "--",
      Ns: "--",
      funddatas: {}
    };
  },
  methods: {
    forgetPoss() {
      this.$router.push('/myFavors/accountManage/safetyCentre')
    },
    topeges() {
      console.log("执行下一步");
    },
    getnewdata(a) {
      let date = a;
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours();
      let mf =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let jjj = yy + "-" + mm + "-" + dd + " " + "15:00:00";
      // let aaa = new Date(jjj);
      this.newdates = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      // let bbb = new Date(this.newdates);
      let dds = date.getDate() + 1;
      this.Ns = yy + "-" + mm + "-" + dds + " " + "15:00:00";
      // if (bbb >= aaa) {
      //   this.txts = "明天15点(节假日顺延)";
      //   let dds = date.getDate() + 2;
      //   let ees = date.getDate() + 3;
      //   this.Ns = yy + "-" + mm + "-" + dds;
      //   this.Es = yy + "-" + mm + "-" + ees;
      // } else {
      //   this.txts = "15点";
      //   let dds = date.getDate() + 1;
      //   let ees = date.getDate() + 2;
      //   this.Ns = yy + "-" + mm + "-" + dds;
      //   this.Es = yy + "-" + mm + "-" + ees;
      // }
    },
    toGos() {
      this.$router.go(-3);
    }
  },
  created() {
    this.funddatas = this.$route.params.okey;
    // this.banknums = this.funddatas.funddatas.banklist.bankAccount.slice(-4);
    this.getnewdata(this.funddatas.dates);
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;
  .publicfundprocess_cont {
    background: #fff;
    // min-height: 1290px;
    padding-bottom: 30px;
    width: 930px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .true_ {
        width: 120px;
        height: 120px;
        margin-bottom: 30px;
      }
      .true_ti {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        .tis_ {
          font-size: 20px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #25293d;
        }
        .timers_ {
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #a5a5a5;
          margin-top: 14px;
        }
      }
      .timerisold_ {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
        font-weight: 300;
        text-align: CENTER;
        color: #25293d;
        .timers__ {
          color: #ce9b63;
        }
      }
      .side_box_ {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        .rduis_ {
          border: 1px solid #d8b691;
          border-radius: 50%;
          // width: 87px;
          // height: 87px;
          font-size: 22px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #b88141;
          padding: 14px 22px;
        }
        i {
          display: inline-block;
          width: 38px;
          // height: 3px;
          margin-left: 10px;
          margin-right: 10px;
          border: 1px dashed #b88141;
        }
      }
      .side_txt_ {
        margin-top: 36px;
        .flex_side {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .left_side {
            width: 355px;
            font-size: 18px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            text-align: LEFT;
            color: #25293d;
          }
          .ac_ {
            color: #ce9b63 !important;
          }
        }
      }
      .cards_tis {
        width: 646px;
        height: 64px;
        background: rgba(255, 245, 234, 0.5);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        span {
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;
          text-align: center;
        }
      }
      .card_timers {
        margin-top: 20px;
        display: flex;
        width: 100%;
        align-items: center;
        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }
        .r_timers {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          text-align: left;
          color: #25293d;
        }
      }
      .card_timers_ {
        margin-top: 24px;
        display: flex;
        width: 100%;
        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }
        .r_timers {
          .top_ {
            display: flex;
            // align-items: center;
            .imgs_ {
              width: 28px;
              height: 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;
              span {
                color: #a5a5a5;
              }
            }
          }
          .center_ {
            display: flex;
            margin-top: 30px;
            // align-items: center;
            .imgs_ {
              width: 28px;
              height: 28px;
              text-align: center;
              img {
                width: 7px;
                height: 25px;
              }
            }
            .top_name_ {
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #a5a5a5;
              margin-left: 8px;
              span {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #e45247;
              }
            }
          }
          .footer_ {
            display: flex;
            // align-items: center;
            margin-top: 30px;
            .imgs_ {
              width: 28px;
              height: 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;
              span {
                color: #a5a5a5;
              }
            }
          }
        }
      }
      .topage_ {
        display: inline-block;
        width: 240px;
        height: 48px;
        background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
        border-radius: 2px;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #b88141;
        margin-top: 44px;
      }
    }
  }
}
</style>
